<template>
    <v-container fluid>
      <v-row>
        <v-col>
          <h2>Order {{order.id}}</h2>
        </v-col>
      </v-row>
      <v-row class="tight-row">
        <v-col>
          Total: {{order.total | currency}}
        </v-col>
      </v-row>
      <v-row class="tight=row">
        <v-col>
          Notes: {{order.deliveryInstructions}}
        </v-col>
      </v-row>
      <v-row class="padless">
        <container-module />
      </v-row>
      <v-row class="padless">
        <order-assistant />
      </v-row>
      <v-row class="padless">
        <receipt />
      </v-row>
    </v-container>
</template>

<script>
import ContainerModule from '../components/ContainerModule';
import OrderAssistant from '../components/OrderAssistant';
import Receipt from '../components/Receipt';

export default {
  created() {
    this.$store.dispatch('loadOrder', this.$route.params.orderId);
  },
  components: {
    ContainerModule,
    OrderAssistant,
    Receipt
  },
  filters: {
    simplifyDate(val) {
      const date = new Date(val);
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  },
  computed: {
    order() {
      return this.$store.state.order;
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('resetOrder');
    this.$store.commit('resetContainers');
    next();
  }
};
</script>

<style>
.print-view {
  color: black !important;
}

.tight-row {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
</style>